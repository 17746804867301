import React from 'react';
import ContentMiddleBanner from './ContentMiddleBanner';

const Content = ({ contentArray, title, middleBannerContent }) => {
  const middleIndex = Math.ceil(contentArray.length / 2);

  return (
    <>
      <div className='why-choose-us'>
        <h2 className='why-choose-us-heading' id='why-choose-us'>{title}</h2>
        {
          contentArray.map((item, index) => {
            return (
              <div key={index}>
                {index === middleIndex && middleBannerContent && <ContentMiddleBanner middleBannerContent={middleBannerContent} />}

                <section className={index % 2 === 0 ? 'alignment-one container' : 'alignment-two container'}>
                  <div className="alignment-one-text">
                    <h5>{item.title}</h5>
                    <h2>{item.subtitle}</h2>
                    <p>
                      {item.content.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                  <div className="alignment-one-image">
                    <img src={item.image} alt="content-image" />
                  </div>
                </section>
              </div>
            )
          })
        }
      </div>
    </>
  );
};

export default Content;

import React from 'react';

const GrantTimeline = () => {

    return (
        <div className="timeline-container">
            <h1>Our Solutions</h1>
            <section className="alignment-one container">
                <ul className="alignment-one-text">
                    <li>Our relationship with technology should be defined by us having control of our own data, not the other way around.</li>
                    <li>Big tech companies have been built and led by young founders in their 20’s who are driven by self-interest, control and ambition. Furthermore, these founders are funded by investors who are driven by personal profit and gain which promote self-interest.</li>
                    <li>Coupled together, these forces realize their goals at the expense of the users thus leading to products and services that reflect their egos and self-interest.</li>
                </ul>
                <div className="alignment-one-image">
                    <img src="/images/sec2E.jpeg" alt="Man holding a product" />
                </div>
            </section>
            <section className="alignment-two container mt-5">
                <div className="alignment-one-text">
                    <li>This dichotomy, while having created transformative solutions for the world and its infrastructure, has also become increasingly centralized. This centralization is growing at an alarming pace, where namely, the world is controlled by very few people today. AIML will only cause this centralization to grow at an even more accelerated pace, further increasing the gap between the haves and the have nots.</li>
                    <li>Finally, while everything is technologically more interconnected, causing a world of convenience to exist, nevertheless, this convenience has a hidden cost. </li>

                </div>
                <div className="alignment-one-image">
                    <img src="/images/sec2B.jpeg" alt="Man holding a product" />
                </div>
            </section>
            <section className="alignment-one container">
                <ul className="alignment-one-text">

                    <li>The endless strings of API integrations of interlocking complementary technologies that most people don’t see, only succeed at providing marginally improved services built on multiple layers of interlocking technology all layering their own fees causing services to get more expensive in the name of convenience.</li>
                    <li>In addition, due to centralization, the cost of marketing products and services on multiple platforms has also skyrocketed. Unbeknownst to users, all these costs are being passed down to them.</li>
                    <li>This rise in costs, while not visible, is probably the main contributor to the inflation storm that most people are feeling but are unable to address. </li>
                </ul>
                <div className="alignment-one-image">
                    <img src="/images/sec2A.jpeg" alt="Man holding a product" />
                </div>
            </section>
        </div>
    );
};

export default GrantTimeline;

import React from 'react'
import HeroSection from './GrantsSectionComponents/HeroSection'
import GrantTimeline from './GrantsSectionComponents/GrantTimeline'

const Grants = () => {
  return (
    <>
      <HeroSection/>
      {/* <section className="alignment-two container mt-5">
        <div className="alignment-one-text">
        <p>Over the last 30 years, we have gone from floppy disks, audio cassettes and Atari to a world of social networks, ecommerce marketplaces, global supply chains, financial payments systems, Artificial Intelligence and Blockchain ledger technologies.</p>
        <p>With this rapid change has come a dire need for moral, ethical and social governance for a new era, which is only going to get more accelerated.</p>

          </div>
        <div className="alignment-one-image">
          <img src="/images/sec2D.png" alt="Man holding a product" />
        </div>
      </section> */}
      <GrantTimeline/>
    </>
  )
}

export default Grants

import React from 'react'
import HeroSection from './HomeSectionComponents/HeroSection'
import ImpactSection from './HomeSectionComponents/ImpactSection'
import CommunityGrantRoundSection from './HomeSectionComponents/CommunityGrantRoundSection'
import MembershipResources from './HomeSectionComponents/MembershipResources'
import SecuritySection from './HomeSectionComponents/SecuritySection'

const Home = () => {
  return (
    <>
      <div className=''>
        <HeroSection/>
        <ImpactSection/>
        <SecuritySection/>
        <MembershipResources/>
      </div>
    </>
  )
}

export default Home

import React from 'react'

const DonationTerms = () => {

    const termsArray = [
        {
            section: "The Feature",
            content: "The Round Up Donation feature allows buyers in the US purchasing on Krizaar with a credit or debit card to round up their purchase price to the nearest dollar and donate the difference to AIBC Foundation Inc, a 501(c)(3) nonprofit. Donations go directly to AIBC Foundation Inc, not to Krizaar."
        },
        {
            section: "Purchases and Donations",
            content: "If you opt for this feature, the donation amount will be shown before you complete your order. It will also be displayed as a separate charge on your bank statement. If successful, the donation will appear on your receipt, but Krizaar holds no further responsibility for reporting donations. Refunds for purchases do not include donations, and any issues related to donations should be addressed directly with AIBC Foundation Inc."
        },
        {
            section: "Non-Profit Support",
            content: "Currently, donations support only the AIBC Foundation Inc. Krizaar may add or remove organisations at any time without notice. Krizaar is not responsible for how donations are used by AIBC Foundation Inc, nor for any potential tax benefits or deductions related to donations."
        },
        {
            section: "Indemnity and Liability",
            content: "You agree to indemnify Krizaar if legal claims arise from your use or misuse of the feature. Krizaar and its affiliates are not liable for any indirect, special, or punitive damages, and its liability is limited to $100 or the amount paid to Krizaar in the last 12 months, whichever is higher."
        },
        {
            section: "Warranties",
            content: "Krizaar offers its services 'as is' without any express or implied warranties. The company does not guarantee continuous availability, error correction, or that services will meet your expectations. Some jurisdictions may not allow these limitations."
        },
        {
            section: "General",
            content: "This feature is optional for each qualifying purchase, and you are not obligated to use it. Krizaar reserves the right to modify these Terms, reject donations, or discontinue the feature without notice. Any donations made before termination will still be processed as per these Terms."
        }
    ];

    return (
        <>
            <section>
                <div className="container">
                    <div className='terms-of-service'>
                        <h1 className="my-3">Donation Terms</h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }}>
                            <div className='mt-1'>
                                <p style={{ textAlign: 'justify' }}>These Terms govern your use of Krizaar's optional "Round Up Donation" feature, allowing you to donate spare change when making purchases. By using this feature, you agree to abide by these Terms, Krizaar’s Privacy Policy, and the Terms of Use (collectively referred to as "Agreements"). In case of any conflict, Krizaar's Terms of Use take precedence. Use of the feature is also subject to AIBC Foundation Inc's Privacy Policy. If you disagree with any part of the Agreements or AIBC Foundation Inc's Privacy Policy, you should not use the feature.</p>
                                <p style={{ textAlign: 'justify' }}>By donating, you authorize AIBC Foundation Inc, a charitable organization, to charge the donation to your credit or debit card on file with Krizaar. Krizaar will remain the Merchant of Record for your main purchase as per the Krizaar Payments Policy.</p>
                            </div>
                        </div>
                        {
                            termsArray.map((term, index) => (
                                <div className='checkout__step-header' style={{ cursor: 'auto' }} key={index}>
                                    <div className=' d-flex gap-4'>
                                        <div className="checkout__step-number">
                                            <span>{index + 1}</span>
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-start'>
                                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>{term.section}</h5></div>
                                            <div className=' d-flex gap-3 mt-1'>
                                                <p style={{ textAlign: 'justify' }}>{term.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className='checkout__step-header' style={{ cursor: 'auto' }}>
                            <div className='mt-1'>
                                <p style={{ textAlign: 'justify' }}>For further details about seller fundraising, see Krizaar’s additional guidelines.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DonationTerms

import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Home from './Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Grants from './Grants'
import Proposals from './Proposals'
import SelectProposals from './SubmitProposalSection/SelectProposals'
import CreateGovernanceProposal from './SubmitProposalSection/CreateGovernanceProposal'
import CreateGrantmakingProposal from './SubmitProposalSection/CreateGrantmakingProposal'
import CreateMembershipProposal from './SubmitProposalSection/CreateMembershipProposal'
import Donate from './Donate'
import DonateCheckout from './DonateCheckout'
import DonationTerms from './DonationTerms'
import Swap from './swap_stake/Swap'
import Stake from './swap_stake/Stake'
import Affluence from './krizaar_pay/KrizaarAffluence'
import SellersClub from './krizaar_pay/SellersClub'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-philosophy" element={<Grants />} />
          <Route path="/proposals" element={<Proposals />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/donationterms" element={<DonationTerms />} />
          <Route path="/donate/checkout" element={<DonateCheckout />} />
          <Route path="/proposals/select" element={<SelectProposals />} />
          <Route path="/proposals/create/governance" element={<CreateGovernanceProposal />} />
          <Route path="/proposals/create/grantmaking" element={<CreateGrantmakingProposal />} />
          <Route path="/proposals/create/membership" element={<CreateMembershipProposal />} />

          <Route path="/swap" element={<Swap />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/affluence" element={<Affluence />} />
          <Route path="/sellersclub" element={<SellersClub />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App

import React, { useState } from 'react';
import { FaBars, FaRegClock } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { PiCurrencyDollarSimple } from 'react-icons/pi';
import { RiGovernmentLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';

const data = [
    {
        title: 'Committee Ratification Vote: DAO Committee Seat 2024',
        category: 'Governance',
        status: 'closed',
        ended: '3 months ago'
    },
    {
        title: 'Committee Selection Process for 2024',
        category: 'Governance',
        status: 'closed',
        ended: '6 months ago'
    },
    {
        title: 'Resignation of DAO Committee Members',
        category: 'Membership',
        status: 'closed',
        ended: '6 months ago'
    },
    {
        title: 'DAO Donor Floor',
        category: 'Governance',
        status: 'closed',
        ended: '6 months ago'
    },
    {
        title: 'Ratification of Community Vote',
        status: 'closed',
        ended: '1 year ago'
    },
    {
        title: 'Election of a New Committee Member',
        category: 'Grantmaking',
        status: 'closed',
        ended: '1 year ago'
    },
    {
        title: 'Light Paper Ratification',
        category: 'Governance',
        status: 'closed',
        ended: '1 year ago'
    },
    {
        title: 'Donor Tokenomics',
        category: 'Governance',
        status: 'closed',
        ended: '2 years ago'
    },
    {
        title: 'Big Green DAO Governance Portal Proposal',
        category: 'Governance',
        status: 'closed',
        ended: '2 years ago'
    },
    {
        title: 'Inclusion of 1ETH Donors and higher in CR4',
        category: 'Governance',
        status: 'closed',
        ended: '2 years ago'
    }
    // Add more data as needed
];
const categories = [
    { name: 'All', icon: <FaBars/>, color: 'black' },
    { name: 'Governance', icon: <RiGovernmentLine/> , color: 'blue' },
    { name: 'Grantmaking', icon: <PiCurrencyDollarSimple/>, color: 'green' },
    { name: 'Membership', icon: <MdPeople/> , color: 'orangered' }
];

const statuses = ['All', 'Active', 'Closed', 'Pending Ratification'];

const Proposals = () => {
    const [filterCategory, setFilterCategory] = useState('All');
    const [filterStatus, setFilterStatus] = useState('All');
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const filteredData = data.filter(item => {
        return (filterCategory === 'All' || item.category === filterCategory) &&
            (filterStatus === 'All' || item.status.toLowerCase() === filterStatus.toLowerCase());
    });

    const PER_PAGE = 5;
    const offset = currentPage * PER_PAGE;
    const currentPageData = filteredData.slice(offset, offset + PER_PAGE);
    const pageCount = Math.ceil(filteredData.length / PER_PAGE);

    return (
        <div className="proposals-container">
            <div className="top-bar">
                <div className='status'>
                    {statuses.map(status => (
                        <button
                            key={status}
                            className={filterStatus === status ? 'active' : ''}
                            onClick={() => {
                                setFilterStatus(status);
                                setCurrentPage(0);
                            }}
                        >
                            {status}
                        </button>
                    ))}
                </div>
                <button className="submit-new-proposal">
                    <NavLink to='/proposals/select' style={{color: 'white', textDecoration: 'none'}}>Submit New Proposal</NavLink>
                </button>
            </div>
            <div className="proposals-container2">
                <div className="sidebar col-lg-3 col-md-6 col-sm-12 col-12">
                    <h6>FILTER BY CATEGORY</h6>
                    {categories.map(category => (
                        <button
                            key={category.name}
                            className={filterCategory === category.name ? 'active' : ''}
                            onClick={() => {
                                setFilterCategory(category.name);
                                setCurrentPage(0);
                            }}
                        >
                            <span style={{ color: category.color }}>
                                {category.icon}
                            </span>
                            {category.name}
                        </button>
                    ))}
                </div>
                <div className="proposals col-lg-9 col-md-6 col-sm-12 col-12">
                <div className='proposals-pagination-top'>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                    </div>
                    <div className='mb-4'>
                        {currentPageData.map((item, index) => (
                            <div key={index} className="proposal">
                                <h6>{item.title}</h6>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                    <p className='proposal-status m-0'>{item.status}</p>
                                    <p className='proposal-category m-0'>
                                        {item?.category === 'Governance' ?
                                            <span style={{
                                                backgroundColor: '#9eb7ea',
                                                border: '1px solid #658ae6',
                                                color: 'black'
                                            }}>Governance</span> :
                                            item?.category === 'Grantmaking' ?
                                                <span style={{
                                                    backgroundColor: '#9edbc3',
                                                    border: '1px solid #1b5d43',
                                                    color: 'black'
                                                }}>Grantmaking</span> :
                                                item?.category === 'Membership' ?
                                                    <span style={{
                                                        backgroundColor: '#f6b796',
                                                        border: '1px solid #ee7635',
                                                        color: 'black'
                                                    }}>Membership</span> : ''}
                                    </p>
                                    <p className='m-0 d-flex align-items-center' style={{ gap: '5px' }}><FaRegClock /> Voting ended {item.ended}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='proposals-pagination'>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Proposals;
import React, { useState } from 'react';
import { CiSettings } from 'react-icons/ci';
import { FaChevronDown } from 'react-icons/fa';
import SelectTokenModal from './SelectTokenModal';
import WalletConnectModal from './WalletConnectModal';
import QRCodeModal from './QRCodeModal';
import PaymentProcessingModal from './PaymentProcessingModal';
import SettingsModal from './SettingsModal';
import Header from '../krizaar_pay/Header';
import './swapstake.css'

const Stake = () => {
    const [selectedCurrency1, setSelectedCurrency1] = useState('ETH');
    const [selectedCurrency2, setSelectedCurrency2] = useState('DAI');
    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [currentDropdown, setCurrentDropdown] = useState(null);

    const currencies = [
        { value: 'ADA', label: 'ADA', image: '/images/ada.png' },
        { value: 'BNB', label: 'BNB', image: '/images/bnb.png' },
        { value: 'ETH', label: 'ETH', image: '/images/eth.png' },
        { value: 'DASH', label: 'DASH', image: '/images/dash.png' },
        { value: 'DAI', label: 'DAI', image: '/images/dai.png' },
        { value: 'ETC', label: 'ETC', image: '/images/etc.png' },
        { value: 'NEXO', label: 'NEXO', image: '/images/nexo.png' },
        { value: 'UNI', label: 'UNI', image: '/images/uni.png' },
    ];

    const handleSelectCurrency1 = (currency) => {
        setSelectedCurrency1(currency);
    };

    const handleSelectCurrency2 = (currency) => {
        setSelectedCurrency2(currency);
    };

    const openTokenModal = (dropdown) => {
        setCurrentDropdown(dropdown);
        setIsTokenModalOpen(true);
    };

    const closeTokenModal = () => {
        setIsTokenModalOpen(false);
        setCurrentDropdown(null);
    };

    const onSelectCurrency = (currency) => {
        if (currentDropdown === 'dropdown1') {
            setSelectedCurrency1(currency);
        } else if (currentDropdown === 'dropdown2') {
            setSelectedCurrency2(currency);
        }
        closeTokenModal();
    };

    const openWalletModal = () => {
        setIsWalletModalOpen(true);
    };

    const closeWalletModal = () => {
        setIsWalletModalOpen(false);
    };

    const openQRCodeModal = () => {
        setIsQRCodeModalOpen(true);
    };

    const closeQRCodeModal = () => {
        setIsQRCodeModalOpen(false);
    };

    const handleWalletSelect = (wallet) => {
        closeWalletModal();
        openQRCodeModal();
    };

    const openSettingsModal = () => {
        setIsSettingsModalOpen(true);
    };

    const closeSettingsModal = () => {
        setIsSettingsModalOpen(false);
    };

    return (
        <div className='swap-main-div'>
            {/* <Header /> */}
            <div className="swap-container">
                <div className="swap-box">
                    <div className='swap-title'>
                        <div>
                            <h4>Swap</h4>
                            <p>Trade tokens in an instant</p>
                        </div>
                        <div className='settings-icon' onClick={openSettingsModal}>
                            <CiSettings />
                        </div>
                    </div>
                    <div className="swap-input">
                        <div className="custom-select" onClick={() => openTokenModal('dropdown1')}>
                            <div className="selected-option">
                                <img src={currencies.find(cur => cur.label === selectedCurrency1).image} alt={selectedCurrency1} />
                                <p className='m-0'>{selectedCurrency1}</p>
                                <FaChevronDown className="arrow" />
                            </div>
                        </div>
                        <input id="eth-input" type="text" placeholder="500" />
                        <span className="usd-value">~156271.40 USD</span>
                    </div>

                    <div className="swap-input">
                        <div className="custom-select" onClick={() => openTokenModal('dropdown2')}>
                            <div className="selected-option">
                                <img src={currencies.find(cur => cur.label === selectedCurrency2).image} alt={selectedCurrency2} />
                                <p className='m-0'>{selectedCurrency2}</p>
                                <FaChevronDown className="arrow" />
                            </div>
                        </div>
                        <input id="dai-input" type="text" placeholder="22619.5" />
                        <span className="usd-value">~22673.50 USD</span>
                    </div>

                    <div className="swap-details">
                        <div className='swap-details2'>
                            <h5>Swap Details</h5>
                            <p><span>Total:</span><span> $0.00</span></p>
                            <p><span>Staked:</span><span> $0.00</span></p>
                            <p><span>Available:</span><span> $0.00</span></p>
                        </div>
                    </div>
                    <button className="connect-wallet-btn" onClick={openWalletModal}>Connect Wallet</button>
                </div>
                <SelectTokenModal isOpen={isTokenModalOpen} onClose={closeTokenModal} onSelectCurrency={onSelectCurrency} />
                <WalletConnectModal isOpen={isWalletModalOpen} onClose={closeWalletModal} onWalletSelect={handleWalletSelect} />
                <PaymentProcessingModal isOpen={isQRCodeModalOpen} onClose={closeQRCodeModal} />
                <SettingsModal isOpen={isSettingsModalOpen} onClose={closeSettingsModal} />
            </div>
        </div>
    );
};

export default Stake;
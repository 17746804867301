import React from 'react';
import { NavLink } from 'react-router-dom';

const MembershipResources = () => {
    return (
        <>
            <div className="membership-resources text-dark pb-0">
                <div className="content">
                    <h1>About Us</h1>
                    <h2>AIBC DAO: Pioneers of Ethical Technology</h2>
                    <p>
                        Founded on the principles of decentralization and community governance, AIBC DAO harnesses the power of AI and Blockchain to address the ethical challenges of our digital age. Our organization operates as a Decentralized Autonomous Organization (DAO), ensuring that every voice is heard and every decision reflects our collective values. Join us in redefining the relationship between technology and society, fostering a future where innovation and integrity go hand in hand.
                    </p>
                    {/* <div className="buttons">
                                <a href="#" rel="noopener noreferrer">
                                    <div className="buttons-div">
                                        <img src="/images/svg1.svg" alt="discord" />
                                        <p className='text-dark'>Donate Now</p>
                                    </div>
                                </a>
                                <NavLink to="/our-philosophy" rel="noopener noreferrer">
                                    <div className="buttons-div">
                                        <img src="/images/svg3.svg" alt="discord" />
                                        <p className='text-dark'>About The DAO</p>
                                    </div>
                                </NavLink>
                            </div> */}
                </div>
            </div>
            <div className="membership-resources text-dark py-0">
                <div className="content">
                    <h1>Our Solution</h1>
                    <h2>Redefining Our Relationship with Technology </h2>
                    <p>
                    In an era marked by rapid technological advancements, AIBC DAO offers a visionary solution to the ethical dilemmas posed by AI and Blockchain. We believe that control over data should lie with the users, not the tech giants. Our approach addresses the centralization of power by fostering decentralized solutions that prioritize community needs and ethical considerations. By tackling issues like cybersecurity threats, data privacy, and the hidden costs of technological convenience, we strive to create a sustainable and just digital ecosystem. Join us in recalibrating our technological landscape to ensure it serves humanity responsibly and equitably.
                    </p>
                </div>
            </div>
            <div className="membership-resources text-dark pt-0">
                <div className="content">
                    <h1>Join Us in Making a Difference</h1>
                    <h2>Together, We Can Create a Brighter Future </h2>
                    <p>
                    The AIBC Foundation is committed to transforming the technological landscape for the betterment of society. Your participation is pivotal in our mission to ensure that AI and Blockchain technologies are harnessed ethically and responsibly. Whether through membership, contributing to our sweepstakes, or engaging in our clubs, every action you take helps build a future where technology uplifts and unites us all. Join us today and be a part of a movement that champions integrity, innovation, and positive change. 
                    </p>
                </div>
            </div>
        </>
    );
};

export default MembershipResources;

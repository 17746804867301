import React, { useState } from 'react';
import { FaArrowLeft, FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const CreateGovernanceProposal = () => {
    const [proposalType, setProposalType] = useState('Governance');
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [specification, setSpecification] = useState('');
    const [motivation, setMotivation] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [choices, setChoices] = useState(['Yes', 'No', 'Abstain']);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showPreview, setShowPreview] = useState(false);

    const handleAddChoice = () => {
        setChoices([...choices, '']);
    };

    const handleChoiceChange = (index, value) => {
        const newChoices = [...choices];
        newChoices[index] = value;
        setChoices(newChoices);
    };

    const handleRemoveChoice = (index) => {
        const newChoices = choices.filter((_, i) => i !== index);
        setChoices(newChoices);
    };

    const handlePreviewChange = () => {
        setShowPreview(!showPreview)
    }

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }

    return (
        <div className='select-proposal'>
            <div className="proposal-container">
                <div className="create-proposal-container">
                    <div className="create-proposal-header">
                        <button className="back-button" onClick={handleBack}><FaArrowLeft /></button>
                        <span className="create-proposal-header-text">New Governance Proposal</span>
                    </div>
                    <div className='create-proposal-information'>
                        <p className="governance-badge">Governance</p>
                        <p className='proposal-info mb-4'>
                            This type of proposal allows members of the DAO to propose and vote on changes to the organization's policies and procedures. These proposals can address important matters such as the DAO's decision-making process, strategic direction, governance structure, and other relevant topics. Governance proposals enable the community to have a say in how the DAO is run and ensure that it continues to operate in a fair and transparent manner.
                        </p>
                    </div>
                    <div className="create-proposal-type">
                        <label>Proposal type</label>
                        <select value={proposalType} onChange={(e) => setProposalType(e.target.value)}>
                            <option value="Governance">Governance</option>
                        </select>
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter the question you would like to ask the community"
                        />
                    </div>
                    {!showPreview ?
                        <>
                            <div className="create-proposal-input-group">
                                <label>Summary</label>
                                <textarea
                                    value={summary}
                                    onChange={(e) => setSummary(e.target.value)}
                                    placeholder="Summarize your proposal in a few sentences"
                                    rows={5}
                                />
                            </div>
                            <div className="create-proposal-input-group">
                                <label>Specification</label>
                                <textarea
                                    value={specification}
                                    onChange={(e) => setSpecification(e.target.value)}
                                    placeholder="Describe your proposal in detail, including all the specifics you would like voters to consider"
                                    rows={5}
                                />
                            </div>
                            <div className="create-proposal-input-group">
                                <label>Motivation/Impact</label>
                                <textarea
                                    value={motivation}
                                    onChange={(e) => setMotivation(e.target.value)}
                                    placeholder="Why is this proposal necessary and/or important? If successful, what impact will this proposal have on the DAO?"
                                    rows={5}
                                />
                            </div>
                            <div className="create-proposal-input-group">
                                <label>Conclusion <span>(optional)</span></label>
                                <textarea
                                    value={conclusion}
                                    onChange={(e) => setConclusion(e.target.value)}
                                    placeholder="What final information would you like to share about your proposal?"
                                    rows={5}
                                />
                            </div>
                        </> : <></>}
                    <div className='preview-and-edit-button'>
                        {showPreview ? <button className='preview-button' onClick={handlePreviewChange}>Edit <FaEdit /></button> :
                            <button className='preview-button' onClick={handlePreviewChange}>Preview <FaEye /></button>
                        }
                    </div>
                    <div className="create-proposal-choices">
                        <label>Choices</label>
                        {choices.map((choice, index) => (
                            <div key={index} className="create-proposal-choice">
                                <input
                                    type="text"
                                    value={choice}
                                    onChange={(e) => handleChoiceChange(index, e.target.value)}
                                    placeholder="Choice"
                                />
                                <button
                                    className="create-proposal-remove-choice-button"
                                    onClick={() => handleRemoveChoice(index)}
                                >
                                    ✖
                                </button>
                            </div>
                        ))}
                        <div className='create-proposal-add-choice-button'>
                            <button className="" onClick={handleAddChoice}><FaPlus /></button>
                        </div>
                    </div>
                    <div className="create-proposal-voting-period mb-5">
                        <label>Voting period</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Start</label>
                                <input
                                    type="datetime-local"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className='selection-item'>
                                <label>End</label>
                                <input
                                    type="datetime-local"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="create-proposal-submit-button">Submit Proposal</button>
                </div>
                <div className="py-4">
                    <button className="create-proposal-submit-button">
                        Connect Wallet
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateGovernanceProposal;

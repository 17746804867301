import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
  const [isProposalPage, setisProposalPage] = useState(false)
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/proposals')) {
      setisProposalPage(true)
    }
  }, [])
  return (
    <nav className={`navbar navbar-expand-lg navbar-light fixed-top custom-navbar ${isProposalPage ? 'proposal-page' : ''}`}>
      <div className="container-fluid">
        <NavLink className="navbar-brand d-flex align-items-center" to="/">
          {/* <img className='img-fluid' src='/images/shuffle_logo.png' alt='Logo' /> */}
          <h1>AIBC Foundation</h1>
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3 mx-3">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">DAO Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/proposals">Proposals</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/our-philosophy">Our Philosophy</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/donate">Donate Now</NavLink>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Explore
              </a>
              <ul className="dropdown-menu">
                <li><NavLink className="dropdown-item" to="/swap">Swap</NavLink></li>
                <li><NavLink className="dropdown-item" to="/stake">Stake</NavLink></li>
                <li><NavLink className="dropdown-item" to="/affluence">Affluence</NavLink></li>
                <li><NavLink className="dropdown-item" to="/sellersclub">Sellers Club</NavLink></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;

import React, { useState } from 'react';

const PaymentForm = () => {

    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvc, setCVC] = useState('');
    const [coverFees, setCoverFees] = useState(false);
    const [donationType, setDonationType] = useState('one-time');
    const [frequency, setFrequency] = useState('monthly');
    const [donationAmount, setDonationAmount] = useState('15');
    const [currency, setCurrency] = useState('usd');
    const [customAmount, setCustomAmount] = useState('');
    const [isCustomAmount, setIsCustomAmount] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [hideName, setHideName] = useState(false);
    const [email, setEmail] = useState('');
    const [contactFuture, setContactFuture] = useState(true);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [source, setSource] = useState(''); // Add the missing state for source

    const handleCustomAmountClick = () => {
        setIsCustomAmount(true);
        setDonationAmount('other');
    };

    return (
        <div className='donate-now-form-container'>
            <div className="payment-form">
                <div className='payment-form-header text-center'>
                    <h1>DONATE NOW</h1>
                    <p>Donate to support community-directed initiatives that grow home, school, and community across the country. </p>
                </div>
                <div className="donation-type">
                    <button
                        onClick={() => setDonationType('one-time')}
                        className={donationType === 'one-time' ? 'active' : ''}
                    >
                        One-time
                    </button>
                    <button
                        onClick={() => setDonationType('recurring')}
                        className={donationType === 'recurring' ? 'active' : ''}
                    >
                        Recurring
                    </button>
                </div>
                {donationType === 'recurring' && (
                    <div className="donation-frequency">
                        <div className='d-flex flex-column' style={{ width: '60%' }}>
                            <label>Donation frequency</label>
                            <select
                                value={frequency}
                                onChange={(e) => setFrequency(e.target.value)}
                            >
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly (every 3 months)</option>
                                <option value="semi-annually">Semi-annually (every 6 months)</option>
                                <option value="yearly">Yearly (every 12 months)</option>
                            </select>
                        </div>
                    </div>
                )}

                <div className="donation-amount">
                    <button
                        onClick={() => {
                            setDonationAmount('15');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '15' ? 'active' : ''}
                    >
                        $15
                    </button>
                    <button
                        onClick={() => {
                            setDonationAmount('50');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '50' ? 'active' : ''}
                    >
                        $50
                    </button>
                    <button
                        onClick={() => {
                            setDonationAmount('100');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '100' ? 'active' : ''}
                    >
                        $100
                    </button>
                    <button
                        onClick={() => {
                            setDonationAmount('250');
                            setIsCustomAmount(false);
                        }}
                        className={donationAmount === '250' ? 'active' : ''}
                    >
                        $250
                    </button>

                    {isCustomAmount ? (
                        <input
                            type="text"
                            value={customAmount}
                            placeholder="Enter amount"
                            onChange={(e) => setCustomAmount(e.target.value)}
                            onBlur={() => setDonationAmount(customAmount)}
                        />
                    ) : (
                        <button
                            onClick={handleCustomAmountClick}
                            className={donationAmount === 'other' ? 'active' : ''}
                        >
                            $ Other
                        </button>
                    )}
                    <select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                    >
                        <option value="usd">USD</option>
                    </select>
                </div>
                <hr className='my-5' />
                <h3>Your Information</h3>
                <div className="donor-info row m-0">
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>First Name *</label>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Last Name *</label>
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group flex-row col-12">
                        <input
                            type="checkbox"
                            checked={hideName}
                            onChange={(e) => setHideName(e.target.checked)}
                        />
                        <label className='m-0'>Hide my name from the public.</label>
                    </div>
                    <div className="donar-input-group col-12">
                        <label>Email *</label>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group flex-row col-12">
                        <input
                            type="checkbox"
                            checked={contactFuture}
                            onChange={(e) => setContactFuture(e.target.checked)}
                        />
                        <label>It's okay to contact me in the future.</label>
                    </div>
                    <div className="donar-input-group col-12">
                        <label>Address *</label>
                        <input
                            type="text"
                            placeholder="Enter your address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-12">
                        <label>Address 2</label>
                        <input
                            type="text"
                            placeholder="Enter your address"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>ZIP *</label>
                        <input
                            type="text"
                            placeholder="ZIP"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>City *</label>
                        <input
                            type="text"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>State *</label>
                        <input
                            type="text"
                            placeholder="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Country *</label>
                        <input
                            type="text"
                            placeholder="Country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Phone Number *</label>
                        <input
                            type="text"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-12">
                        <label>Leave a comment</label>
                        <textarea
                            placeholder="Write your comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>How did you hear about us?</label>
                        <select
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        >
                            <option value="">Select an option</option>
                            <option value="friend">Friend</option>
                            <option value="online">Online</option>
                            <option value="event">Event</option>
                        </select>
                    </div>
                </div>
                <hr className='my-4' />
                <h3>Payment Details</h3>
                <div className="payment-info row m-0">
                    <div className="donar-input-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Card Number *</label>
                        <input
                            type="text"
                            placeholder="Card Number"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-3 col-md-3 col-sm-6 col-6">
                        <label>Expiry Date *</label>
                        <input
                            type="text"
                            placeholder="MM/YY"
                            value={expiryDate}
                            onChange={(e) => setExpiryDate(e.target.value)}
                        />
                    </div>
                    <div className="donar-input-group col-lg-3 col-md-3 col-sm-6 col-6">
                        <label>CVC *</label>
                        <input
                            type="text"
                            placeholder="CVC"
                            value={cvc}
                            onChange={(e) => setCVC(e.target.value)}
                        />
                    </div>
                    <p className="terms">
                        By donating, you agree to our <a href="#">Privacy Policy</a> and <a href="#">Classy’s Terms</a> and Privacy Policy. Classy facilitates your donations.
                    </p>
                    <div className="cover-fees">
                        <input
                            type="checkbox"
                            checked={coverFees}
                            onChange={(e) => setCoverFees(e.target.checked)}
                        />
                        <label>I’d like to cover all transaction fees so 100% of my donation goes to Big Green.</label>
                    </div>
                    <button type="submit" className="give-now">GIVE NOW!</button>
                    <p className="donation-summary">
                        Monthly donation <span>$15.00 USD</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PaymentForm;

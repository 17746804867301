import React, { useState } from 'react';
import { FaArrowLeft, FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const CreateGrantmakingProposal = () => {
    const [proposalType, setProposalType] = useState('Governance');
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [specification, setSpecification] = useState('');
    const [motivation, setMotivation] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [choices, setChoices] = useState(['Yes', 'No', 'Abstain']);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showPreview, setShowPreview] = useState(false);

    const handleAddChoice = () => {
        setChoices([...choices, '']);
    };

    const handleChoiceChange = (index, value) => {
        const newChoices = [...choices];
        newChoices[index] = value;
        setChoices(newChoices);
    };

    const handleRemoveChoice = (index) => {
        const newChoices = choices.filter((_, i) => i !== index);
        setChoices(newChoices);
    };

    const handlePreviewChange = () => {
        setShowPreview(!showPreview)
    }

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }

    return (
        <div className='select-proposal'>
            <div className="proposal-container">
                <div className="create-proposal-container">
                    <div className="create-proposal-header">
                        <button className="back-button" onClick={handleBack}><FaArrowLeft /></button>
                        <span className="create-proposal-header-text">New Grantmaking Proposal</span>
                    </div>
                    <div className='create-proposal-information'>
                        <p className="grantmaking-badge">Grantmaking</p>
                        <p className='proposal-info mb-4'>
                        A Grantmaking Proposal is a prerequisite for launching of a "Grants Round", which are held quarterly, throughout the fiscal year. This proposal will establish the parameters of each grants round, including, total available budget, as well as minimum and maximum grant sizes. In each grants round the DAO is required to distribute at least 20% and no more than 30% of its total treasury. Once passed, a call for applicants will be released and a new Grants Round will be opened in the DAO's Grants Portal.
                        </p>
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter the question you would like to ask the community"
                        />
                    </div>
                    
                    <div className='preview-and-edit-button'>
                        {showPreview ? <button className='preview-button' onClick={handlePreviewChange}>Edit <FaEdit /></button> :
                            <button className='preview-button' onClick={handlePreviewChange}>Preview <FaEye /></button>
                        }
                    </div>
                    {!showPreview ?
                        <>
                            <div className="create-proposal-input-group">
                                <label>Description</label>
                                <textarea
                                    // value={summary}
                                    // onChange={(e) => setSummary(e.target.value)}
                                    placeholder="Describe any relevant grants details"
                                    rows={5}
                                />
                            </div>
                        </> : <></>}

                    <div className="create-proposal-input-group">
                        <label>Minnimum Grant Size (USD)</label>
                        <input
                            type="text"
                            // value={title}
                            // onChange={(e) => setTitle(e.target.value)}
                            placeholder="Must be greater than zero"
                        />
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Maximum Grant Size (USD)</label>
                        <input
                            type="text"
                            // value={title}
                            // onChange={(e) => setTitle(e.target.value)}
                            placeholder="Must be greater than minimum grant size"
                        />
                    </div>
                    <div className="create-proposal-input-group">
                        <label>Max Grant Size (USD)</label>
                        <input
                            type="text"
                            // value={title}
                            // onChange={(e) => setTitle(e.target.value)}
                            placeholder="Must be greater than maximum grant size"
                        />
                    </div>
                    <div className="create-proposal-choices">
                        <label>Choices</label>
                        {choices.map((choice, index) => (
                            <div key={index} className="create-proposal-choice">
                                <input
                                    type="text"
                                    value={choice}
                                    onChange={(e) => handleChoiceChange(index, e.target.value)}
                                    placeholder="Choice"
                                />
                                <button
                                    className="create-proposal-remove-choice-button"
                                    onClick={() => handleRemoveChoice(index)}
                                >
                                    ✖
                                </button>
                            </div>
                        ))}
                        <div className='create-proposal-add-choice-button'>
                            <button className="" onClick={handleAddChoice}><FaPlus /></button>
                        </div>
                    </div>
                    <div className="create-proposal-voting-period mb-5">
                        <label>Voting period</label>
                        <div className='create-proposal-period-selection'>
                            <div className='selection-item'>
                                <label>Start</label>
                                <input
                                    type="datetime-local"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className='selection-item'>
                                <label>End</label>
                                <input
                                    type="datetime-local"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="create-proposal-submit-button">Submit Proposal</button>
                </div>
                <div className="py-4">
                    <button className="create-proposal-submit-button">
                        Connect Wallet
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateGrantmakingProposal;

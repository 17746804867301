import React from 'react';
import { NavLink } from 'react-router-dom';

const ImpactSection = () => {
    return (
        <>

            <div className="impact-container mt-5">
                {/* <img src={img} className='img-fluid impact-background' alt='background-img' /> */}
                {/* <img src={leaf} className='img-fluid leaf1' alt='background-img' />
                <img src={leaf} className='img-fluid leaf2' alt='background-img' /> */}
                <div className="big-green-impact my-5">
                    <div className='container'>
                        <h2><span>Our Mission</span></h2>
                        <h2>Championing Moral and Ethical Tech Advancement </h2>
                        <p>At the Artificial Intelligence & Blockchain (AIBC) Foundation Inc, our mission is to nurture, build, and oversee the global development of AI and Blockchain technologies. We are dedicated to ensuring these powerful tools are applied ethically and responsibly, driving progress that benefits all of humanity. Together, we strive to create a world where technology enhances lives while upholding the highest moral standards.</p>
                        <NavLink to='/donate'>
                            <button className="apply-grant-button">Donate Now</button>
                        </NavLink>
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default ImpactSection;

import React from 'react';
import { NavLink } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="hero-section">
    {/* <img src={heroimg} className='img-fluid' alt='background-img'/> */}
      <div className="container">
        <div className='hero-content'>

          <h3>Welcome to AIBC DAO</h3>
          <h1>Empowering Ethical Innovation for a Brighter Tomorrow</h1>
          <p>Join us in shaping a future where Artificial Intelligence and Blockchain technologies serve humanity with integrity and purpose.</p>
          <div className="hero-buttons">
            <NavLink to="/our-philosophy" className="btn btn-first">Our Philosophy</NavLink>
            <NavLink to="/donate" className="btn btn-secondary">Donate Now</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

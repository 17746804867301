import React from 'react';

const SecuritySection = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>HOW TO ENGAGE</h2>
                <h3>Become a Catalyst for Positive Change</h3>
                <p>Engage with AIBC DAO through our dynamic and inclusive community structure. Explore the three vibrant pathways to contribute and grow: </p>
                <div className="features">
                    <div className="feature-card">
                        <img src="/images/svg1.svg" alt="3D Secure checkouts" />
                        <h3>CLUBS</h3>
                        <p className='m-0'>Join our diverse membership categories—Everyone, VIP, and VVIP. Whether you're a passionate supporter or a dedicated leader, there's a place for you to make an impact. Enjoy exclusive benefits, earn rewards, and participate in global networking events that amplify our collective vision.
                        </p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg2.svg" alt="PCI-compliant servers" />
                        <h3>NFTS</h3>
                        <p>Receive a unique NFT badge as a symbol of your commitment. Showcase it on your social profiles and unlock special discounts with our partners. Our NFTs not only represent your membership but also your dedication to ethical technology.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/images/svg3.svg" alt="Payment data encryption" />
                        <h3>SWEEPSTAKES</h3>
                        <p>Participate in our transparent sweepstakes powered by smart contracts. Every entry supports initiatives that promote the ethical use of AI and Blockchain. Stand a chance to win rewards while making a meaningful difference. </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecuritySection;

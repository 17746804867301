import React from 'react';
import { NavLink } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="hero-section grant-hero-section pt-5">
      <div className="container">
        <div className='hero-content'>
          <h1>Our Philosophy</h1>
          {/* <p className="subheading">The first non-profit led philanthropic DAO</p> */}
          <p>With the rapid rise of Artificial Intelligence & Machine Learning technology (AIML), a new frontier is upon us, however, it is not without its challenges or threats.</p>
          <p>Cybersecurity hacks, algorithm manipulations, online targeting, data and privacy leaks have become all too common. It is evident that the time to recalibrate our relationship with technology in a more moral, ethical and responsible way is NOW.</p>
          <div className="hero-buttons">
            <NavLink to="/donate" className="btn btn-light">Donate Now</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

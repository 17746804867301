import React from 'react'
import { NavLink } from 'react-router-dom'

const Donate = () => {
  return (
    <>
      <div className='donate-now-container'>
        <div className='donate-now-inside-container'>
            <h1>JOIN OUR AIBC FOUNDATION MISSION</h1>
            <h3>Donate today to help people and communities across the country.</h3>
            <NavLink to='/donate/checkout'>
                <button className='apply-grant-button'>Donate Now</button>
            </NavLink>
        </div>
      </div>
    </>
  )
}

export default Donate
